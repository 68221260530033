<template>
  <v-container
    class="pa-0"
    style="overflow-y: auto; height: 100%; max-width: 360px;"
    fluid
  >
    <v-card>
      <v-list>
        <v-list-item class="py-1">
          <v-select
            v-model="value.sortBy"
            :disabled="disabled"
            hide-details
            :items="sortKeys"
            :label="$t('deconve.sortBy')"
            @change="onFilterChanged"
          />
        </v-list-item>

        <v-list-item class="py-1">
          <v-select
            v-model="value.sortOrder"
            :disabled="disabled"
            hide-details
            :items="orderKeys"
            :label="$t('deconve.sortOrder')"
            @change="onFilterChanged"
          />
        </v-list-item>

        <v-list-item class="py-1">
          <v-select
            v-model="value.status"
            :disabled="disabled"
            hide-details
            :items="showModeKeys"
            :label="$t('deconve.status')"
            @change="onFilterChanged"
          />
        </v-list-item>

        <v-list-item class="py-1">
          <v-select
            v-model="value.reviewStatus"
            :disabled="disabled"
            hide-details
            :items="reviewStatusKeys"
            :label="$t('deconve.reviewStatus')"
            @change="onFilterChanged"
          />
        </v-list-item>

        <v-list-item class="py-1">
          <v-select
            v-model="value.hasSimilar"
            :disabled="disabled"
            hide-details
            :items="similarProfilesStatusKeys"
            :label="$t('deconve.filterProfiles')"
            @change="onFilterChanged"
          />
        </v-list-item>

        <v-list-item
          :disabled="disabled"
          class="py-1 ma-0"
        >
          <v-col>
            <v-row>
              <span>
                {{ $t('deconve.added') }}
              </span>
            </v-row>
            <v-row class="py-1">
              <date-time-range-picker
                :start-date-time="value.createdAfter"
                :end-date-time="value.createdBefore"
                :mode="value.createdDateOption"
                :any-period="true"
                :disabled="disabled"
                @change="onCreatedAtDateChanged"
              />
            </v-row>
          </v-col>
        </v-list-item>

        <v-list-item :disabled="disabled">
          <v-col>
            <v-row>
              <span>
                {{ $t('deconve.workspace') }}
              </span>
            </v-row>
            <v-row
              v-if="value.workspaceName === undefined"
              class="py-2"
            >
              <v-btn
                outlined
                :disabled="disabled"
                color="neutral"
                :fab="$vuetify.breakpoint.mobile"
                :x-small="$vuetify.breakpoint.mobile"
                @click="openWorkspaceDialog"
              >
                <v-icon :left="!$vuetify.breakpoint.mobile">
                  {{ icons.addWorkspace }}
                </v-icon>

                <div v-if="!$vuetify.breakpoint.mobile">
                  {{ $t('deconve.workspace') }}
                </div>
              </v-btn>
            </v-row>

            <v-row
              v-else
              class="py-1 align-center"
            >
              <v-chip
                label
                outlined
                class="pa-1"
              >
                {{ value.workspaceName }}
              </v-chip>
              <v-btn
                outlined
                fab
                x-small
                :disabled="disabled"
                color="neutral"
                class="ml-1"
                @click="openWorkspaceDialog"
              >
                <v-icon>
                  {{ icons.addWorkspace }}
                </v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-list-item>

        <v-list-item :disabled="disabled">
          <v-col>
            <v-row>
              <span>
                {{ $t('deconve.unit') }}
              </span>
            </v-row>
            <v-row
              v-if="value.unitName === undefined"
              class="py-2 align-center"
            >
              <v-btn
                outlined
                :disabled="disabled"
                color="neutral"
                :fab="$vuetify.breakpoint.mobile"
                :x-small="$vuetify.breakpoint.mobile"
                @click="openUnitModal"
              >
                <v-icon :left="!$vuetify.breakpoint.mobile">
                  {{ icons.addUnit }}
                </v-icon>

                <div v-if="!$vuetify.breakpoint.mobile">
                  {{ $t('deconve.unit') }}
                </div>
              </v-btn>
            </v-row>

            <v-row
              v-else
              class="py-1 align-center"
            >
              <v-chip
                label
                outlined
                class="pa-1"
              >
                {{ value.unitName }}
              </v-chip>
              <v-btn
                outlined
                fab
                x-small
                :disabled="disabled"
                color="neutral"
                class="ml-1"
                @click="openUnitModal"
              >
                <v-icon>
                  {{ icons.addUnit }}
                </v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-list-item>

        <v-list-item :disabled="disabled">
          <v-col>
            <v-row>
              <span>
                {{ $t('deconve.people') }}
              </span>
            </v-row>

            <v-row
              v-if="value.tags.length === 0"
              class="py-2 align-center"
            >
              <v-btn
                outlined
                :disabled="disabled"
                color="neutral"
                :fab="$vuetify.breakpoint.mobile"
                :x-small="$vuetify.breakpoint.mobile"
                @click="openTagModal"
              >
                <v-icon :left="!$vuetify.breakpoint.mobile">
                  {{ icons.addTag }}
                </v-icon>

                <div v-if="!$vuetify.breakpoint.mobile">
                  {{ $t('deconve.tags') }}
                </div>
              </v-btn>
            </v-row>

            <v-row
              v-else
              class="py-1 align-center"
            >
              <tag
                v-for="(tagId, idx) in value.tags"
                :key="`${tagId}-${idx}`"
                class="pa-1"
                :tag-id="tagId"
              />
              <v-btn
                outlined
                fab
                :disabled="disabled"
                x-small
                color="neutral"
                class="ml-1"
                @click="openTagModal"
              >
                <v-icon>
                  {{ icons.addTag }}
                </v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-list-item>
      </v-list>
      <v-card-actions class="justify-end">
        <v-btn
          :disabled="!hasChanged"
          color="primary"
          :small="$vuetify.breakpoint.mobile"
          @click="clearFilters"
        >
          {{ $t('deconve.clearFilters') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <tags-manager-dialog ref="tagDialog" />
    <workspaces-dialog ref="workspaceDialog" />
    <unit-selection-dialog ref="unitDialog" />
  </v-container>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import DateTimeRangePicker from '@/components/DateTimeRangePicker.vue';
import TagsManagerDialog from '@/components/TagsManagerDialog.vue';
import WorkspacesDialog from '@/components/WorkspacesDialog.vue';
import UnitSelectionDialog from '@/components/UnitSelectionDialog.vue';
import Tag from '@/components/Tag.vue';

import { mdiTagPlusOutline, mdiStorePlusOutline, mdiBriefcaseOutline } from '@mdi/js';
import {
  SUPER_PROFILE_STATUS, REVIEW_STATUS, SIMILAR_PROFILES_STATUS, SORT_ORDER,
} from '@/utils/faceidFilters';

export default {
  name: 'SuperProfileFilter',
  components: {
    Tag,
    TagsManagerDialog,
    WorkspacesDialog,
    UnitSelectionDialog,
    DateTimeRangePicker,
  },
  props: {
    value: { type: Object, required: true },
    hasChanged: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      sortKeys: [
        { text: this.$t('deconve.personName'), value: 'name' },
        { text: this.$t('deconve.date'), value: 'created_at' },
        { text: this.$t('deconve.updateDate'), value: 'updated_at' },
      ],
      orderKeys: [
        { text: this.$t('deconve.ascendingOrder'), value: SORT_ORDER.ascending },
        { text: this.$t('deconve.descendingOrder'), value: SORT_ORDER.descending },
      ],
      showModeKeys: [
        { text: this.$t('deconve.allFemale'), value: SUPER_PROFILE_STATUS.all },
        { text: this.$t('deconve.actives'), value: SUPER_PROFILE_STATUS.activated },
        { text: this.$t('deconve.inTrash'), value: SUPER_PROFILE_STATUS.inTrash },
      ],
      reviewStatusKeys: [
        { text: this.$t('deconve.all'), value: REVIEW_STATUS.all },
        { text: this.$t('deconve.awaitingReview'), value: REVIEW_STATUS.waiting },
        { text: this.$t('deconve.reviewedMale'), value: REVIEW_STATUS.reviewed },
      ],
      similarProfilesStatusKeys: [
        { text: this.$t('deconve.all'), value: SIMILAR_PROFILES_STATUS.all },
        { text: this.$t('deconve.withSimilarProfiles'), value: SIMILAR_PROFILES_STATUS.withSimilarProfiles },
        { text: this.$t('deconve.noSimilarProfiles'), value: SIMILAR_PROFILES_STATUS.noSimilarProfiles },
      ],
      icons: {
        addTag: mdiTagPlusOutline,
        addUnit: mdiStorePlusOutline,
        addWorkspace: mdiBriefcaseOutline,
      },
    };
  },
  methods: {
    openWorkspaceDialog() {
      this.$refs.workspaceDialog.open(this.value.workspaceId).then((workspace) => {
        this.value.workspaceId = workspace?.id;
        this.value.workspaceName = workspace?.name;

        this.onFilterChanged();
      }).catch(() => {
        // User canceled the dialog
      });
    },
    openUnitModal() {
      this.$refs.unitDialog.open(this.value.unitId).then((selectedUnit) => {
        this.value.unitId = selectedUnit?.id;
        this.value.unitName = selectedUnit?.name;
        this.onFilterChanged();
      }).catch(() => {
        // User canceled the dialog
      });
    },
    openTagModal() {
      // We are updating the code style to use only the list of tag ids. After update the tag
      // dialog, we will remove this part
      const currentTags = this.value.tags.map((id) => ({ id }));

      this.$refs.tagDialog.open(currentTags).then((tags) => {
        if (tags) {
          this.value.tags = tags.map(({ id }) => id);
          this.onFilterChanged();
        }
      });
    },
    onCreatedAtDateChanged({ startDate, endDate, mode }) {
      this.value.createdBefore = endDate;
      this.value.createdAfter = startDate;
      this.value.createdDateOption = mode;

      this.onFilterChanged();
    },
    onFilterChanged() {
      this.$emit('change');
    },
    clearFilters() {
      this.$emit('clear');
    },
  },
};
</script>
