<template>
  <v-container
    class="pa-0"
    style="overflow-y: auto; height: 100%; width: 100%"
    fluid
  >
    <v-data-iterator
      class="d-flex flex-column"
      style="height: 100%"
      :items="superProfilesList"
      :server-items-length="superProfiles.length"
      :loading="isLoading"
      hide-default-footer
      @update:page="onPageNumberUpdated"
    >
      <template v-slot:header>
        <div
          class="pa-3 pb-4 d-flex align-center"
          style="border-bottom: 1px solid;"
          :style="{
            borderColor: $vuetify.theme.themes.light.border,
          }"
        >
          <input-text
            v-model="filterOptions.search"
            max-width="440px"
            left-icon="mdi-magnify"
            :placeholder="$t('deconve.search')"
          />

          <v-menu
            :close-on-content-click="false"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :color="hasFilterChanged? 'primary' : 'neutral'"
                :class="hasFilterChanged? 'primaryHighlight' : 'white'"
                :small="$vuetify.breakpoint.mobile"
                v-bind="attrs"
                class="mx-2"
                v-on="on"
              >
                <v-icon>
                  {{ hasFilterChanged? icons.selectedFilterIcon : icons.outlineFilterIcon }}
                </v-icon>
              </v-btn>
            </template>
            <super-profile-filter
              v-model="filterOptions"
              :has-changed="hasFilterChanged"
              @change="onFilterChanged"
              @clear="clearFilters"
            />
          </v-menu>
        </div>
      </template>

      <template v-slot:loading>
        <span
          class="ml-1 pa-2"
        >
          {{ $t('deconve.loading') }}
        </span>
      </template>

      <template v-slot:no-data>
        <span
          class="ml-1 pa-2 neutralPrimary--text text-subtitle-2 text-sm-subtitle-1 font-weight-bold"
        >
          {{ $t('deconve.noSuperProfile') }}
        </span>
      </template>

      <template v-slot:default="props">
        <div
          style="flex: 1; overflow-y: auto;"
          :style="{
            backgroundColor: $vuetify.theme.themes.light.background,
          }"
        >
          <v-progress-linear
            v-if="isLoading"
            :indeterminate="true"
          />

          <div class="pa-2">
            <div
              class="d-flex flex-wrap"
            >
              <v-col
                v-for="item in props.items"
                :key="item.id"
                class="pa-1"
                cols="12"
                sm="6"
                md="4"
                lg="3"
                :data-cy="item.id"
              >
                <super-profile-preview
                  :id="item.id"
                />
              </v-col>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:footer>
        <footer-data-iterator
          :items-per-page-array="itemsPerPageArray"
          :items-per-page="filterOptions.itemsPerPage"
          :page="filterOptions.page"
          :total-data="numberOfSuperProfiles"
          @changeItensPerPage="updateItemsPerPage"
          @changePage="onPageNumberUpdated"
        />
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import InputText from '@/components/InputText.vue';
import FooterDataIterator from '@/components/FooterDataIterator.vue';

import { mapActions, mapGetters } from 'vuex';

import { booleanToSuperProfileStatus, booleanToSimilarProfilesStatus, defaultSuperProfileFilterOptions } from '@/utils/faceidFilters';

import { mdiFilter, mdiFilterOutline } from '@mdi/js';
import SuperProfilePreview from './SuperProfilePreview.vue';
import SuperProfileFilter from './SuperProfileFilter.vue';

export default {
  name: 'SuperProfiles',
  components: {
    InputText,
    FooterDataIterator,
    SuperProfilePreview,
    SuperProfileFilter,
  },
  data() {
    return {
      isLoading: false,
      itemsPerPageArray: [4, 8, 12],
      filterOptions: defaultSuperProfileFilterOptions(),
      icons: {
        selectedFilterIcon: mdiFilter,
        outlineFilterIcon: mdiFilterOutline,
      },
    };
  },
  computed: {
    ...mapGetters({
      superProfiles: 'faceid/getSuperProfiles',
      dataIteratorSettings: 'faceid/getSuperProfilesFiltersSettings',
    }),
    hasFilterChanged() {
      const defaultOption = defaultSuperProfileFilterOptions();

      return Boolean(
        this.filterOptions.search !== defaultOption.search
        || this.filterOptions.sortBy !== defaultOption.sortBy
        || this.filterOptions.sortOrder !== defaultOption.sortOrder
        || this.filterOptions.status !== defaultOption.status
        || this.filterOptions.tags.length > 0
        || this.filterOptions.createdAfter !== defaultOption.createdAfter
        || this.filterOptions.createdBefore !== defaultOption.createdBefore
        || this.filterOptions.createdDateOption !== defaultOption.createdDateOption
        || this.filterOptions.workspaceId !== defaultOption.workspaceId
        || this.filterOptions.workspaceName !== defaultOption.workspaceName
        || this.filterOptions.unitId !== defaultOption.unitId
        || this.filterOptions.unitName !== defaultOption.unitName
        || this.filterOptions.hasSimilar !== defaultOption.hasSimilar
        || this.filterOptions.reviewStatus !== defaultOption.reviewStatus,
      );
    },
    superProfilesList() {
      return this.superProfiles.items;
    },
    numberOfSuperProfiles() {
      return this.superProfiles.total;
    },

    search() {
      return this.filterOptions.search;
    },
  },
  watch: {
    search() {
      this.onFilterChanged();
    },
  },
  created() {
    this.getDataIteratorInfo();
    this.onFilterChanged();
  },
  methods: {
    ...mapActions({
      fetchSuperProfiles: 'faceid/fetchSuperProfiles',
      saveSuperProfileIteratorSettings: 'faceid/setSuperProfileIteratorSettings',
    }),
    saveIteratorSettings() {
      this.saveSuperProfileIteratorSettings(this.filterOptions);
    },
    handleFetchSuperProfiles() {
      this.isLoading = true;

      this.fetchSuperProfiles().then(() => {
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },
    onFilterChanged() {
      this.saveIteratorSettings();
      this.handleFetchSuperProfiles();
    },
    getDataIteratorInfo() {
      if (this.dataIteratorSettings) {
        const {
          search,
          itemsPerPage,
          page,
          sortBy,
          sortOrder,
          inTrash,
          tags,
          createdAfter,
          createdBefore,
          createdDateOption,
          workspaceId,
          workspaceName,
          unitId,
          unitName,
          hasSimilar,
          reviewStatus,
        } = this.dataIteratorSettings;

        this.filterOptions = {
          search,
          itemsPerPage,
          page,
          sortBy,
          sortOrder,
          status: booleanToSuperProfileStatus(inTrash),
          tags,
          createdAfter,
          createdBefore,
          createdDateOption,
          workspaceId,
          workspaceName,
          unitId,
          unitName,
          hasSimilar: booleanToSimilarProfilesStatus(hasSimilar),
          reviewStatus,
        };
      }
    },
    clearFilters() {
      this.filterOptions = defaultSuperProfileFilterOptions();
      this.onFilterChanged();
    },
    updateItemsPerPage(number) {
      this.filterOptions.itemsPerPage = number;
      this.filterOptions.page = 1;
      this.onFilterChanged();
    },
    onPageNumberUpdated(page) {
      this.filterOptions.page = page;
      this.onFilterChanged();
    },
  },
};
</script>
